<template>
<div>
  <rel-header>
    <form>
      <e-row class="m-b-sm" mr>
        <e-col><h5>Bens com mais tempo em Estoque</h5></e-col>
      </e-row>
      <e-row mr>
        <e-col>
          <e-row class="large-mr erp-s-field-bold" mr>
            <e-col>
              <erp-s-field label="Comitente:">
                <global-pessoa-input placeholder="Deixe em branco para filtrar todos" :tags="['comitente']" v-model="filters.comitente" layout="float-icon input-hide-loading" :hide-underline="false" />
              </erp-s-field>
            </e-col>
            <e-col style="max-width: 200px">
              <erp-s-field label="Tempo mínimo:">
                <u-input type="number" v-model="filters.tempoMinimo" />
              </erp-s-field>
            </e-col>
          </e-row>
        </e-col>
        <e-col style="max-width: 120px; text-align: right;" class="flex items-center justify-end">
          <erp-s-field label="">
            <u-btn label="Gerar" color="green" :loading="gerando" @click="gerar('html')" />
          </erp-s-field>
        </e-col>
      </e-row>
    </form>
  </rel-header>
  <div v-if="resultado" class="sl-body">
    <div class="base-table">
      <table>
        <thead>
        <tr>
          <th class="row1-table"><span>Colaborador</span></th>
          <th v-for="d in resultado.periodo">{{ convertDate(d) }}</th>
          <th>Total</th>
        </tr>
        </thead>
        <tbody>
        <tr v-for="user in resultado.workedHours">
          <td>
            <div @click="clickPerson(user.person)" class="flex items-center cursor-pointer">
            <div
                 class="person-avatar avatar inline hover-scale min">
              <img v-if="user.person.photo" width="30" class="" :src="user.person.photo"/>
              <div v-else class="img-fake">{{ firstLettersName(user.person.name) }}</div>
            </div>
            <span class="m-l-xs">{{ user.person.name }}</span>
            </div>
          </td>
          <td v-for="periodo in resultado.periodo">{{ typeof user.workedHours[periodo] !== 'undefined' ? secondsToHours(user.workedHours[periodo].seconds) : '0' }}h</td>
          <td>{{ secondsToHours(user.workedHoursTotal) }}h</td>
        </tr>
        </tbody>
      </table>
    </div>
  </div>
</div>
</template>

<script>
import RelHeader from '@/components/relatorios/layout/RelHeader'
import ERow from "@/components/layout/components/Row"
import ECol from "@/components/layout/components/Col"
import {ErpSField} from "uloc-vue-plugin-erp"
// import DatetimeModernInput from "@/reuse/input/DatetimeModern"
import GlobalPessoaInput from "@/components/pessoa/components/include/pessoa/PessoaInput"
import {relatorio} from "@/domain/relatorios/services"
// import {datePtToEn} from "@/utils/date"
import GlobalPersonMixin from "components/pessoa/globalPersonMixin"
import {formatDistance} from "date-fns"
import ptBRLocale from "date-fns/locale/pt-BR"
import htmlView from 'components/relatorios/layout/htmlView'
import {donwloadFile} from "@/utils/downloadFile"
import {UInput} from "uloc-vue"

export default {
  name: "TempoTrabalho",
  mixins: [GlobalPersonMixin],
  data () {
    return {
      cache: {
        timestamp: null
      },
      fake: {
      },
      filters: {
        comitente: null,
        tempoMinimo: 30
      },
      gerando: false,
      resultado: null
    }
  },
  methods: {
    htmlView: htmlView,
    load (format) {
      this.gerar(format)
    },
    gerar (format = 'html') {
      const filters = JSON.parse(JSON.stringify(this.filters))
      filters.comitente = filters.comitente && filters.comitente.id ? filters.comitente.id : null
      this.$uloc.loading.show({message: 'Gerando relatório'})
      this.gerando = true
      relatorio('tempoEstoque', filters, format)
          .then(response => {
            this.$uloc.loading.hide()
            console.log('XXX 1')
            this.gerando = false
            if (format === 'excel' || format === 'xlsx') {
              // donwloadFile(response)
              return
            } else if(format === 'html') {
              console.log('XXX 2')
              this.htmlView(response.data, () => import('../../layout/RelatorioHtml'), 'Relatório de Tempo de Estoque', 'landscape', {excel: true})
              return
            }
          })
          .catch(error => {
            this.gerando = false
            this.$uloc.loading.hide()
            this.alertApiError(error)
          })
    },
    clickPerson (person) {
      this.gotoPerson(person)
    },
    convertDate (d) {
      return d.replace(/(\d{4})(\d{2})(\d{2})/, '$3/$2/$1')
    },
    secondsToHours (s) {
      return formatDistance(0, s * 1000, {includeSeconds: true, locale: ptBRLocale})
    }
  },
  components: {
    GlobalPessoaInput,
    // DatetimeModernInput,
    ErpSField,
    ECol,
    ERow,
    RelHeader,
    UInput
  }
}
</script>
